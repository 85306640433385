//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LiveChatWidget } from '@livechat/widget-vue/v2'

export default {
  components: {
    LiveChatWidget
  },
  data () {
    return {
      isMounted: false,
      isLCReady: false,
      lc: { license: '8514118', chat_between_groups: false, group: '23' },
      visibility: 'minimized',
      bubbleTransition: false,
      showBubble: false
    }
  },
  watch: {
    isLCReady (val) {
      if (val) {
        setTimeout(() => {
          this.bubbleTransition = true
        }, 500)
      }
    }
  },
  mounted () {
    // console.log('support btn mount', this.$i18n.locale)
    if (this.$i18n.locale === 'en' && this.lc.group !== '28') {
      this.lc.group = '28'
    } else if (this.$i18n.locale === 'pt' && this.lc.group !== '23') {
      this.lc.group = '23'
    } else if (this.$i18n.locale === 'es' && this.lc.group !== '58') {
      this.lc.group = '58'
    } else if (this.$i18n.locale === 'zh' && this.lc.group !== '28') {
      this.lc.group = '28'
    }
    this.isMounted = true
    // console.log({ group: this.lc.group })
  },
  beforeUpdate () {
    // console.log('support btn beforeUpdate', this.$i18n.locale)
    /* Update live chat chatroom according to the language */
    if (this.$i18n.locale === 'en' && this.lc.group !== '28') {
      this.lc.group = '28'
      this.isLCReady = false
    } else if (this.$i18n.locale === 'pt' && this.lc.group !== '23') {
      this.lc.group = '23'
      this.isLCReady = false
    } else if (this.$i18n.locale === 'es' && this.lc.group !== '58') {
      this.lc.group = '58'
      this.isLCReady = false
    } else if (this.$i18n.locale === 'zh' && this.lc.group !== '28') {
      this.lc.group = '28'
      this.isLCReady = false
    }
    // console.log({ group: this.lc.group })
  },
  methods: {
    /* handleNewEvent (event) {
      console.log('LiveChatWidget.onNewEvent', event)
    }, */
    handleHidden (event) {
      if (event && event.visibility === 'hidden') {
        this.visibility = 'minimized'
      }
    },
    openLiveChat () {
      // eslint-disable-next-line no-console
      // console.log(':license==>' + this.lc.license + ';:group=' + this.lc.group + 'LCReady=' + this.bubbleTransition)
      this.visibility = 'maximized'
    },
    LCReady () {
      this.isLCReady = true
    }
  }
}
