import md5 from 'md5'

const ksort = (obj) => {
  const keys = Object.keys(obj).sort()
  const sortedObj = {}
  for (const i in keys) {
    sortedObj[keys[i]] = obj[keys[i]]
  }
  return sortedObj
}

const getSign = (obj) => {
  const sortedObj = ksort(obj)
  let str = ''
  const keys = Object.keys(sortedObj)
  keys.forEach((key) => {
    str = str.concat(key, '=', sortedObj[key], '&')
  })
  const privatekey = 'cd7fe6a9d92b2f4c17b363499ce589f5'
  str = str.concat(privatekey)
  const sign = md5(md5(str).toString()).toString()
  return sign
}

const def = () => false

export default def
export {
  getSign
}

export const setLocalStorage = (key, val) =>
  window.localStorage.setItem(key, val)

export const getLocalStorage = key => window.localStorage.getItem(key)

export const removeLocalStorage = key => window.localStorage.removeItem(key)
