//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      hideInnerMenu: []
    }
  },
  mounted () {
    if (this.list.length) {
      this.list.forEach(() => {
        this.hideInnerMenu.push(true)
      })
    }
  },
  methods: {
    openLiveChat ($event) {
      $event.preventDefault()
      if (window.LC_API) {
        if (window.LC_API.is_loaded()) {
          window.LC_API.open_chat_window()
        }
      }
    },
    clickInnerMenu (index) {
      if (this.hideInnerMenu.length >= (index + 1)) {
        Vue.set(this.hideInnerMenu, index, !this.hideInnerMenu[index])
      }
    }
  }
}
